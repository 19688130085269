<template>
  <div class="calendar-box">
    <div class="container-s">
      <create-edit-obj
        ref="createEditObj"
        prefix="bef"
        objectApi="Event"
        :object-name="$t('label.event')"
        @save="save"
      >
      </create-edit-obj>
      <div class="calendarbox">
        <div class="calendarleft">
          <div class="calen-icon">
            <svg class="icon calen-icon-logo" aria-hidden="true">
              <use href="#icon-cloudtab6_norm"></use>
            </svg>
          </div>
          <div>
            <span v-show="activeViewIndex == 0">{{
              $t("vue_label_calendar_availability_view")
            }}</span>
            <span v-show="activeViewIndex == 1">{{
              $t("vue_label_calendar_day_view")
            }}</span>
            <span v-show="activeViewIndex == 2">{{
              $t("vue_label_calendar_week_view")
            }}</span>
            <span v-show="activeViewIndex == 3">{{
              $t("vue_label_calendar_month_view")
            }}</span>
            <span class="header-date">{{ showDateText }}</span>
          </div>
        </div>
        <div class="calendarright">
          <el-button
            class="arrowleft"
            size="mini"
            @click="changeDate(-1)"
            icon="el-icon-arrow-left"
          >
          </el-button>
          <el-button
            size="mini"
            class="arrowright"
            @click="changeDate(1)"
            icon="el-icon-arrow-right"
          >
          </el-button>
          <el-button
            size="mini"
            class="today"
            :disabled="todayBtn"
            @click="changeDate(0)"
            >{{ $t("today") }}</el-button
          >
          <!--        <el-button size="mini" class="Refresh" icon="el-icon-refresh"></el-button>-->
          <el-dropdown
            trigger="click"
            class="dropdownchoice"
            @command="handleChangeView"
          >
            <el-button size="mini" class="choice" icon="el-icon-date">
              <i class="el-icon-caret-bottom"></i>
            </el-button>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :class="
                  activeViewIndex == 0
                    ? 'current-index dropmenu-base'
                    : 'dropmenu-base'
                "
                command="0"
              >
                <span>{{ $t("vue_label_calendar_availability") }}</span>
                <svg
                  aria-hidden="true"
                  v-show="activeViewIndex == 0"
                  class="icon"
                >
                  <use href="#icon-currentIndexIcon"></use>
                </svg>
              </el-dropdown-item>
              <el-dropdown-item
                :class="
                  activeViewIndex == 1
                    ? 'current-index dropmenu-base'
                    : 'dropmenu-base'
                "
                command="1"
              >
                <span>{{ $t("day") }}</span>
                <svg
                  aria-hidden="true"
                  v-show="activeViewIndex == 1"
                  class="icon"
                >
                  <use href="#icon-currentIndexIcon"></use>
                </svg>
              </el-dropdown-item>
              <el-dropdown-item
                :class="
                  activeViewIndex == 2
                    ? 'current-index dropmenu-base'
                    : 'dropmenu-base'
                "
                command="2"
              >
                <span>{{ $t("week") }}</span>
                <svg
                  aria-hidden="true"
                  v-show="activeViewIndex == 2"
                  class="icon"
                >
                  <use href="#icon-currentIndexIcon"></use>
                </svg>
              </el-dropdown-item>
              <el-dropdown-item
                :class="
                  activeViewIndex == 3
                    ? 'current-index dropmenu-base'
                    : 'dropmenu-base'
                "
                command="3"
              >
                <span>{{ $t("month") }}</span>
                <svg
                  aria-hidden="true"
                  v-show="activeViewIndex == 3"
                  class="icon"
                >
                  <use href="#icon-currentIndexIcon"></use>
                </svg>
              </el-dropdown-item>
              <el-dropdown-item command="4">{{
                $t("label.search.listview")
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-button size="mini" class="newsing" @click="getNewEvent">
            <span>{{ $t("label.event.new") }}</span>
            <i class="el-icon-plus"></i>
          </el-button>
          <div @click="openAside" class="open-aside">
            <svg
              aria-hidden="true"
              v-show="!eventboxshow"
              class="icon morethings"
            >
              <use href="#icon-cebianlan"></use>
            </svg>
            <svg
              aria-hidden="true"
              v-show="eventboxshow"
              class="icon morethings"
            >
              <use href="#icon-menu"></use>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="calendar-content">
      <div
        class="calendar-view-box"
        @click="handleClick"
        :style="{ width: '100%' }"
      >
        <daily
          ref="dateView"
          @goItemDetail="toItemDetail"
          @headerDate="changeHeaderDate"
          v-if="activeViewIndex == 1"
          :showDialog.sync="dialogShow"
          @openNewEvent="getEventDate"
          @updateDialogStatus="updateDialogStatus"
        ></daily>
        <week
          ref="dateView"
          @goItemDetail="toItemDetail"
          @headerDate="changeHeaderDate"
          v-if="activeViewIndex == 2"
          :showDialog.sync="dialogShow"
          @openNewEvent="getEventDate"
          @updateDialogStatus="updateDialogStatus"
        ></week>
        <month
          ref="dateView"
          @goItemDetail="toItemDetail"
          @headerDate="changeHeaderDate"
          v-if="activeViewIndex == 3"
          :showDialog.sync="dialogShow"
          @openNewEvent="getEventDate"
          @updateDialogStatus="updateDialogStatus"
        ></month>
        <available
          ref="dateView"
          @goItemDetail="toItemDetail"
          @headerDate="changeHeaderDate"
          v-if="activeViewIndex == 0"
          :showDialog.sync="dialogShow"
          @openNewEvent="getEventDate"
          @updateDialogStatus="updateDialogStatus"
        ></available>
      </div>

      <div class="calendarmin-box calendar-view-aside">
        <calendarmin
          @gotoDate="goDate"
          @refreshView="freshView"
          @getOnlyCalendar="getOnlyCalendar"
          @giveTip="giveTips"
          :fromViewDate="toAcalendarDate"
          :class="[this.eventboxshow ? 'calendarmin' : 'calendarmin1']"
        ></calendarmin>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @description 日历对象
 * 功能：
 * 1. 日历头部信息展示
 * 2. 日历日期切换
 * 3. 日历视图切换
 * 4. 日历事件新建
 * 5. 日历展示
 * 6. 对象日历展示
 */
import calendarmin from "./calendarmin";
import createEditObj from "./../commonObjects/components/create-edit-obj.vue";
import daily from "./CalendarView/Daily";
import week from "./CalendarView/Week";
import month from "./CalendarView/Month";
import available from "./CalendarView/Available";
// import { getRecType } from "./api.js";
import * as types from "@/store/mutations-types";
export default {
  data() {
    return {
      // 只打开一个日历
      onlyCalendar: {},
      // 路由携带参数
      routeData: {},
      // 今天的日期
      toDay: "",
      // 跳转的日期
      activeDay: "",
      // 当前月
      toMon: "",
      // 跳转的月
      activeMon: "",
      // 视图组件弹窗显示
      dialogShow: false,
      // 传递给小日历的日期
      toAcalendarDate: "",
      // 默认打开周视图
      activeViewIndex: 2,
      // “今天”按钮是否可点击啊
      todayBtn: true,
      // 今天索引
      todayIndex: 0,
      // 日历头部日期展示
      showDateText: "",
      // 侧边栏显示
      eventboxshow: true,
      // 可用性视图状态
      availableStatus: false,
    };
  },
  methods: {
    /**
     * @description 判断是否仅开启其他日历，更改可用性视图状态
     * @param {flag} 对象日历个数
     */
    giveTips(flag) {
      if (flag < 1) {
        this.availableStatus = true;
      } else {
        this.availableStatus = false;
      }
    },
    /**
     * @description 根据可用性视图状态点击时给出提示
     */
    handleClick() {
      if (this.activeViewIndex == 0 && this.availableStatus) {
        this.$message.warning(
          this.$i18n.t("label.calendar.addevent.notice.opencalendarfirst")
        );
      }
    },
    /**
     * @description 获取仅打开的对象日历数据
     * @param {data} 仅打开的对象日历数据
     */
    getOnlyCalendar(data) {
      this.onlyCalendar = data;
    },
    /**
     * @description 新建日历事件保存后的回掉函数
     */
    save() {
      this.$refs.dateView.parentRefresh();
    },
    /**
     * @description 获取当前系统时间的下一个整点以及整点之后一个小时的时间
     */

    getHour() {
      let date = new Date();
      date.setHours(date.getHours() + 1);
      let y = date.getFullYear();
      let m =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let startTime = y + "-" + m + "-" + d + " " + h + ":00:00";
      function getNextHour(intPoint) {
        let dd = new Date(intPoint);
        let Y = dd.getFullYear();
        let M =
          dd.getMonth() + 1 < 10
            ? "0" + (dd.getMonth() + 1)
            : dd.getMonth() + 1;
        let D = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
        let H =
          dd.getHours() + 1 < 10
            ? "0" + (dd.getHours() + 1)
            : dd.getHours() + 1;
        return Y + "-" + M + "-" + D + " " + H + ":00:00";
      }
      return { start: startTime, end: getNextHour(startTime) };
    },
    /**
     * @description 调用通用组件新建
     * @param {dateObj} 日期
     * @param {user} 分配人信息
     * @param {isHeader} 是否头部点击
     */
    openCommonDialog(dateObj, user, isHeader) {
      let op = {
        type: "event",
        timeObj: dateObj,
      };

      if (this.activeViewIndex == 0) {
        // 可用性视图被分配人永远是表头用户（对象日历除外)
        if (user) {
          op.userInfo = user;
        }
      } else {
        // 只打开一个其他日历，并且不是在右上角新建，更改被分配人
        if (this.onlyCalendar.relationid && !isHeader) {
          op.userInfo = {
            userName: this.onlyCalendar.name,
            userId: this.onlyCalendar.relationid,
          };
        }
      }
      if (this.routeData.id) {
        op.config = this.routeData;
      }
      this.$refs.createEditObj.toDoList(op);
    },
    /**
     * @description 关闭悬浮窗
     */
    updateDialogStatus(flag) {
      if (flag < 1) {
        this.dialogShow = false;
      } else {
        this.dialogShow = true;
      }
    },
    /**
     * @description 打开关闭侧边栏
     */
    openAside() {
      this.dialogShow = false;
      this.eventboxshow = !this.eventboxshow;
      setTimeout(() => {
        this.$refs.dateView.parentHandleResize();
      }, 500);
    },
    /**
     * @description 切换日历视图
     */
    handleChangeView(e) {
      this.todayBtn = true;
      this.dialogShow = false;
      this.todayIndex = 0;
      this.activeViewIndex = e;
      if (e === "4") {
        this.$router.push({
          path: "/commonObjects/views/table/Event/event/bef",
        });
      }
    },
    /**
     * @description 前一个，后一个，今天
     */
    changeDate(index) {
      this.dialogShow = false;
      this.$refs.dateView.parentHandleClick(index);
    },
    /**
     * @description 事件详情
     */
    toItemDetail(option) {
      this.$router.push({
        path: `/commonObjects/detail/${option.id}/DETAIL`,
      });
    },
    // 根据子组件回传的日期，更新数据
    /**
     * @description 根据子组件回传的日期，更新数据
     * @param {date} 日期
     * @param {indexDay} 日期索引
     */
    changeHeaderDate(date, indexDay) {
      this.showDateText = date;
      if (indexDay) {
        let dd = new Date(indexDay);
        let y = dd.getFullYear();
        let m =
          dd.getMonth() + 1 < 10
            ? "0" + (dd.getMonth() + 1)
            : dd.getMonth() + 1;
        let d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
        this.activeDay = y + "-" + m + "-" + d;
        this.activeMon = y + "-" + m;
      }
      if (this.activeViewIndex == 3) {
        if (this.activeMon == this.toMon) {
          let dd = new Date();
          let y = dd.getFullYear();
          let m =
            dd.getMonth() + 1 < 10
              ? "0" + (dd.getMonth() + 1)
              : dd.getMonth() + 1;
          let d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
          this.activeDay = y + "-" + m + "-" + d;
          this.todayBtn = true;
        } else {
          this.todayBtn = false;
        }
        this.toAcalendarDate = this.activeDay;
      } else {
        if (this.activeDay == this.toDay) {
          this.todayBtn = true;
        } else {
          this.todayBtn = false;
        }
        this.toAcalendarDate = this.activeDay;
      }
    },
    /**
     * @description 刷新视图
     */
    freshView() {
      this.$refs.dateView.parentRefresh();
    },
    /**
     * @description 跳转到日期
     */
    goDate(date) {
      this.$refs.dateView.parentGoDate(date);
    },
    /**
     * @description 获取今天的日期
     */
    getToday() {
      let dd = new Date();
      let y = dd.getFullYear();
      let m =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      let d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      this.toDay = y + "-" + m + "-" + d;
      this.toMon = y + "-" + m;
    },
    // 获取后一小时
    /**
     * @description 获取后一小时
     * @param {date} 日期
     * @param {flag} n小时数
     * @param {isInBox} 是否在日历视图上点击
     */
    getNextTime(date, flag, isInBox) {
      var dd = new Date(date);
      if (isInBox) {
        if (this.activeViewIndex === 3 || this.activeViewIndex === "3") {
          let y = dd.getFullYear();
          let m =
            dd.getMonth() + 1 < 10
              ? "0" + (dd.getMonth() + 1)
              : dd.getMonth() + 1;
          let d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
          return y + "-" + m + "-" + d + " " + "23:59:59";
        } else {
          dd.setHours(dd.getHours() + flag);
        }
      } else {
        dd.setHours(dd.getHours() + flag);
      }

      let y = dd.getFullYear();
      let m =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      let d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      let h = dd.getHours() < 10 ? "0" + dd.getHours() : dd.getHours();
      let min = dd.getMinutes() < 10 ? "0" + dd.getMinutes() : dd.getMinutes();
      let s = dd.getSeconds() < 10 ? "0" + dd.getSeconds() : dd.getSeconds();
      return y + "-" + m + "-" + d + " " + h + ":" + min + ":" + s;
    },
    /**
     * @description 在日历视图上点击新建事件
     * @param {date} 开始时间
     * @param {userInfo} 被分配人信息
     */
    getEventDate(date, userInfo) {
      let obj = {
        start: date,
        end: this.getNextTime(date, 1, true),
      };
      if (userInfo) {
        this.openCommonDialog(obj, userInfo);
      } else {
        this.openCommonDialog(obj);
      }
    },
    /**
     * 顶部右上角新建事件
     */
    getNewEvent() {
      let date = new Date();
      let y = date.getFullYear();
      let m =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let h =
        date.getHours() < 10
          ? "0" + (date.getHours() + 1)
          : date.getHours() + 1;
      /**
       * 解决当时间是23点时，没有做24点限制
       */
      if (h > 23) {
        h = "00";
      }
      let startTime = y + "-" + m + "-" + d + " " + h + ":00:00";
      let obj = {
        start: startTime,
        end: this.getNextTime(startTime, 1, false),
      };
      this.openCommonDialog(obj, null, 1);
    },
  },
  components: {
    createEditObj,
    calendarmin,
    daily,
    week,
    month,
    available,
  },
  created() {
    this.getToday();
  },
  mounted() {
    if (this.$route.query.id) {
      this.activeViewIndex = 0;
      this.routeData = this.$route.query;
    } else {
      this.routeData = {};
    }
    document.title = this.$setTitle(this.$i18n.t("label.calendar"));
    this.$store.commit(types.SET_TAB_NAME, this.$i18n.t("label.calendar"));
  },
};
</script>

<style lang="scss" scoped>
.calendar-box {
  padding: 0 10px 10px 10px;
  box-sizing: border-box;
}
.container-s {
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  //margin: 10px 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  padding-top: 10px;
  // width: 98.2%;
  width: 100%;
  background-color: #efefef;
}
.calendarmin {
  width: 300px;
  transition: width 0.3s ease;
}

.calendarmin1 {
  width: 0;
  transition: width 0.3s ease;
  display: none;
}
::v-deep .fc-toolbar-title {
  //  display: none;
}
.calendarbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 12px;
  background-color: #f6f6f6;
  .calendarleft {
    display: flex;
    align-items: center;
    .calen-icon {
      width: 37px;
      height: 37px;
      margin-right: 5px;
      .calen-icon-logo {
        width: 100%;
        height: 100%;
      }
    }
    span {
      display: block;
      font-size: 12px;
      line-height: 20px;
      color: #333333;
      margin-left: 5px;
    }
    .header-date {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .calendarright {
    display: flex;
    align-items: center;
    .arrowleft,
    .arrowright {
      height: 25px;
      width: 25px;
      font-size: 14px;
      color: #0a0909;
      padding: 0;
    }
    .today {
      font-size: 12px;
      height: 25px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .allday {
      margin: 10px 0 0 0;
      span:first-child {
        display: inline-block;
        width: 12px;
        height: 12px;
        border: 1px solid #999999;
        margin-right: 5px;
      }
    }
    .Refresh {
      height: 25px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
    }

    .choice {
      height: 25px;
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      i {
        font-size: 12px;
      }
    }

    .newsing {
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1b5297;
    }

    .morethings {
      width: 25px;
      height: 25px;
      margin-left: 10px;
    }
    .dropdownchoice {
      margin: 0 10px;
    }
  }
  .open-aside {
    cursor: pointer;
  }
  .taskmsg {
    display: flex;
    background-color: #f0f0f0;
    padding: 10px 0 10px 20px;
    color: #333333;
  }
  .taskmsgbox {
    display: flex;
    justify-content: space-between;
    .taskmsgleft {
      padding: 10px 0 20px 20px;
      .Be-assigned {
        span {
          font-size: 10px;
        }
        ::v-deep .el-select {
          display: block;
          width: 245px;
        }
        ::v-deep .userchioce {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
      .theme {
        margin: 10px 0 0 0;
        ::v-deep .el-select {
          display: block;
        }
        .themechioce {
          margin-top: 10px;
        }
      }
      .Related-persons {
        margin: 10px 0 0 0;
        ::v-deep .el-select {
          display: block;
        }
      }
      .Related-items {
        margin: 10px 0 0 0;
        ::v-deep .el-select {
          display: block;
        }
      }
    }
    .taskright {
      padding: 20px 0 20px 20px;
      .postion {
        margin-top: 10px;
        margin-bottom: 10px;
        ::v-deep .el-select {
          display: block;
        }
      }
      .timestart {
        display: flex;
        align-items: center;
      }
      .endtime {
        display: flex;
      }
    }
  }
  .taskright {
    padding: 20px 0 20px 20px;
    .postion {
      margin-top: 10px;
      margin-bottom: 10px;
      ::v-deep .el-select {
        display: block;
      }
    }
    .timestart {
      display: flex;
      align-items: center;
    }
    .endtime {
      display: flex;
    }
  }
}
.remarks {
  display: flex;
  background-color: #f0f0f0;
  padding: 10px 0 10px 20px;
  color: #333333;
}
.content {
  padding: 0 20px;
  h5 {
    margin: 15px 0 10px 0;
  }
  textarea {
    resize: none;
    width: 100%;
    outline: none;
    border: 1px solid #dcdcdc;
  }
}
.calendar-content {
  display: flex;
  //height: 565px;
  height: 100%;
  padding-top: 2px;
  .calendar-view-box {
    transition: width 0.3s ease;
  }
  .calendar-view-aside {
    //width: 30%;
  }
}
.calendarmin-box {
  position: relative;
  .calendarmin {
  }
}
.current-index {
  background-color: #ecf5ff;
  color: #66b1ff;
  > span {
  }
  > img {
    width: 15px;
    height: 15px;
  }
}
.dropmenu-base {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  > img {
    width: 15px;
    height: 15px;
  }
}
</style>
