<template>
  <div class="calendarmin">
    <div class="calender_style">
      <div class="select_group">
        <!-- 年选择按钮 -->
        <el-select v-model="yearSelectValue" @change="yearChange">
          <el-option
            v-for="item in yearOptions"
            :key="item.label"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
        <!-- 月选择按钮 -->
        <el-select v-model="monthsValue" @change="monthChange">
          <el-option
            v-for="item in months"
            :key="item.label"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 日历组件 -->
      <wang-calendar
        @dayChange="selectDate"
        :value="currentShowDateToCalender"
      />
    </div>

    <div class="calendartype" v-show="!simpleDate">
      <div class="mycalendar">
        <!-- 我的日历 vue_label_calendar_my_calendar -->
        <span>{{ $t("vue_label_calendar_my_calendar") }}</span>
      </div>
      <el-dropdown trigger="click">
        <div class="addcalendar">
          <svg aria-hidden="true" class="icon xiala_ic">
            <use href="#icon-xiala_ic1"></use>
          </svg>
        </div>
        <el-dropdown-menu slot="dropdown">
          <!-- 新建对象日历 vue_label_calendar_new_object_calendar-->
          <el-dropdown-item @click.native="bojdialogVisible = true">{{
            $t("vue_label_calendar_new_object_calendar")
          }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- "label_chatter_name"名称 -->
      <!-- 添加日历 vue_label_calendar_add_calendar-->
      <el-dialog title="新建公共日历" :visible.sync="dialogVisible" width="40%">
        <div class="addcalendar-body">
          <div class="addcalendar-dai">
            {{ $t("vue_label_calendar_add_calendar") }}
          </div>
          <div class="call"><i>*</i>{{ $t("label_chatter_name") }}</div>
          <div class="person-name">
            <div>
              <el-input v-model="calendarchild"></el-input>
            </div>
            <!-- <div class="Enable">
              <span>启用</span>
              <span></span>
            </div>-->
          </div>
          <!-- 添加共享 vue_label_calendar_add_share -->
          <div class="addshare">{{ $t("vue_label_calendar_add_share") }}</div>
          <div class="calen-group">
            <calendargroup
              :optionArr="member"
              :calendarname="calendarchild"
              ref="calendarchild"
              @changepublic="changepublicdisplay"
            ></calendargroup>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- label_cancel取消 -->
          <!-- label_saveandnew保存并新建 -->
          <!-- label_save保存 -->
          <el-button @click="dialogVisible = false">{{
            $t("label.saveandnew")
          }}</el-button>
          <el-button @click="dialogVisible = false">{{
            $t("label.cancel")
          }}</el-button>
          <el-button type="primary" @click="submitpublic">{{
            $t("label.save")
          }}</el-button>
        </span>
      </el-dialog>
      <!-- 新建对象日历 vue_label_calendar_new_object_calendar-->
      <el-dialog
        :title="$t('vue_label_calendar_new_object_calendar')"
        :visible.sync="bojdialogVisible"
        width="40%"
      >
        <addobjcalendar
          ref="addobjcalendarfun"
          :turnstatus="bojdialogVisible"
          @changestatus="changedisplay"
          :changesonvieew="getmycalendarandothermsg"
          @changeisloading="saveisloading"
        ></addobjcalendar>
        <span slot="footer" class="dialog-footer">
          <!-- label_cancel取消 -->
          <!-- label_save保存 -->
          <el-button @click="bojdialogVisible = false">{{
            $t("label.cancel")
          }}</el-button>
          <el-button
            type="primary"
            @click="submitsave"
            :loading="false"
            ref="savevalue"
            >{{ $t("label.save") }}</el-button
          >
        </span>
      </el-dialog>
    </div>
    <div class="eventtype" v-show="!simpleDate">
      <div class="myevent">
        <div class="myevent-left">
          <!--          <span class="color-tip-myevent" @click="changeColorStatus(myEventItem)"></span>-->
          <span
            v-show="myEventItem.state === '1'"
            :style="{
              backgroundColor: myEventItem.color,
              borderColor: myEventItem.color,
            }"
            class="color-tip-myevent"
            @click="changeColorStatus(myEventItem)"
          ></span>
          <span
            v-show="myEventItem.state === '0'"
            class="color-tip-myevent color-tip"
            @click="changeColorStatus(myEventItem)"
          ></span>
          <!-- label_myevent我的事件 -->
          <span class="color-tip-title">{{ $t("label.myevent") }}</span>
        </div>
        <el-dropdown trigger="click">
          <div class="selectimg">
            <svg aria-hidden="true" class="icon xiala_ic">
              <use href="#icon-xiala"></use>
            </svg>
          </div>
          <!-- 仅显示此日历 vue_label_calendar_show_only_this_calendar-->
          <!-- 共享日历 vue_label_calendar_shared_calendar-->
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="onlychange(myeventmsg[0].id)">{{
              $t("vue_label_calendar_show_only_this_calendar")
            }}</el-dropdown-item>
            <el-dropdown-item @click.native="sharedialogfun">{{
              $t("vue_label_calendar_shared_calendar")
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-dialog
        :title="$t('vue_label_calendar_shared_calendar')"
        :visible.sync="sharedialogVisible"
        width="40%"
        class="sharecalendar"
      >
        <sharecalendar
          ref="sharemsg"
          @changshare="changesharediplay"
          @shareloading="changeshareloading"
        ></sharecalendar>
        <span slot="footer" class="dialog-footer">
          <!-- label_cancel取消 -->
          <!-- label_tabpage_ok确定 -->
          <el-button @click="sharedialogVisible = false">{{
            $t("label.cancel")
          }}</el-button>
          <el-button
            type="primary"
            @click="submitsharesave"
            :loading="false"
            ref="sharefun"
            >{{ $t("label_tabpage_ok") }}</el-button
          >
        </span>
      </el-dialog>
      <div class="scrollstatus" v-loading="loading">
        <ul>
          <li v-for="calendaritem in mycalendarvalue" :key="calendaritem.id">
            <div class="taskcalendar">
              <div class="obj-item">
                <span
                  v-show="calendaritem.state === '1'"
                  :style="{
                    backgroundColor: calendaritem.color,
                    borderColor: calendaritem.color,
                  }"
                  class="color-tip-basic"
                  @click="changeColorStatus(calendaritem)"
                  ref="colortip"
                ></span>
                <span
                  v-show="calendaritem.state === '0'"
                  class="color-tip-basic color-tip"
                  @click="changeColorStatus(calendaritem)"
                ></span>
                <span class="color-tip-title">{{ calendaritem.name }}</span>
              </div>
              <el-dropdown
                trigger="click"
                @visible-change="
                  getselectid(calendaritem.id, calendaritem.type)
                "
              >
                <div class="objectCalendar-icon">
                  <div class="warning2-box" v-if="calendaritem.isAbnormal">
                    <!-- 因有大于等于一个记录的结束字段早于开始字段，所以记录无法在日历中显示。 front-calendar-V1-Unable-to-display-->
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="$t('front-calendar-V1-Unable-to-display')"
                      placement="left"
                    >
                      <div>
                        <svg aria-hidden="true" class="icon warning2">
                          <use href="#icon-warning2"></use>
                        </svg>
                      </div>
                    </el-tooltip>
                  </div>
                  <div class="selectimg">
                    <svg aria-hidden="true" class="icon xiala_ic">
                      <use href="#icon-xiala"></use>
                    </svg>
                  </div>
                </div>
                <!-- 仅显示此日历 vue_label_calendar_show_only_this_calendar-->
                <!-- 编辑日历 vue_label_calendar_edit_calendar-->
                <!-- 删除日历 vue_label_calendar_delete_calendar-->
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    @click.native="onlychange(calendaritem.id)"
                    >{{
                      $t("vue_label_calendar_show_only_this_calendar")
                    }}</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click.native="editcalendar(calendaritem.id)"
                    >{{
                      $t("vue_label_calendar_edit_calendar")
                    }}</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click.native="
                      getdeleteid(calendaritem.id, calendaritem.name)
                    "
                    >{{
                      $t("vue_label_calendar_delete_calendar")
                    }}</el-dropdown-item
                  >
                  <el-dropdown-item>
                    <div>
                      <chiocecolor
                        @getchiocecolor="getchiocecoloritem"
                      ></chiocecolor>
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- label_chat_topic_delete删除 -->
    <!-- label_cancel取消 -->
    <!-- label_tabpage_ok确定 -->
    <el-dialog
      :title="$t('label.chat.topic.delete')"
      :visible.sync="deletedialogVisible"
      width="40%"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div class="deletebut">
        <!-- 确定删除"{{ this.deletename }}"日历吗 -->
        <span>{{
          $t("vue_label_calendar_deleteconfirm", { name: deletename })
        }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deletedialogVisible = false">{{
          $t("label.cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="deleteobjcalendar()"
          ref="deletefun"
          :loading="false"
          >{{ $t("label_tabpage_ok") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- label_cancel取消 -->
    <!-- label_tabpage_ok确定 -->
    <!-- 编辑日历 vue_label_calendar_edit_calendar-->
    <el-dialog
      :title="$t('vue_label_calendar_edit_calendar')"
      :visible.sync="editdialogVisible"
      width="40%"
    >
      <editvalendar
        :deitcalendarid="deitcalendarid"
        ref="editcalendarname"
        @changeeditstatus="changeditdisplay"
        @changeisloading="editisloading"
      ></editvalendar>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editdialogVisible = false">{{
          $t("label.cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="submiteditobjcalendar"
          :loading="false"
          ref="editfun"
          >{{ $t("label_tabpage_ok") }}</el-button
        >
      </span>
    </el-dialog>

    <div class="other" v-show="!simpleDate">
      <div class="othercalendar">
        <!-- 其他日历 vue_label_calendar_other_calendars-->
        <!-- 添加日历 vue_label_calendar_add_calendar-->
        <span>{{ $t("vue_label_calendar_other_calendars") }}</span>
      </div>
      <el-dropdown trigger="click">
        <div class="addcalendar">
          <svg aria-hidden="true" class="icon xiala_ic">
            <use href="#icon-xiala_ic1"></use>
          </svg>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="addcalendarfun">{{
            $t("vue_label_calendar_add_calendar")
          }}</el-dropdown-item>
          <!-- <el-dropdown-item @click.native="dialogVisible = true">新建公共日历</el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
      <!-- 选择日历类型 vue_label_calendar_choose_calendar_type-->
      <el-dialog
        :title="$t('vue_label_calendar_add_calendar')"
        :visible.sync="addcalendardialogVisible"
        width="30%"
      >
        <div class="type-calendar">
          <span>{{ $t("vue_label_calendar_choose_calendar_type") }}</span>
          <div class="chioce-type">
            <addcalendar
              ref="addcalnedar"
              @changeaddcalendarsave="changeaddcalendarstatus"
              @addloading="changeaddloaing"
            ></addcalendar>
          </div>
        </div>
        <!-- label_cancel取消 -->
        <!-- label_add添加 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="addcalendardialogVisible = false">{{
            $t("label.cancel")
          }}</el-button>
          <el-button
            type="primary"
            @click="submitaddcalendarsave"
            :loading="false"
            ref="addfun"
            >{{ $t("label.add") }}</el-button
          >
        </span>
      </el-dialog>
    </div>
    <div class="otherlist" v-show="!simpleDate">
      <ul>
        <li v-for="calendaritem in myother" :key="calendaritem.id">
          <div class="taskcalendar">
            <div class="obj-item">
              <span
                v-show="calendaritem.state === '1'"
                :style="{
                  backgroundColor: calendaritem.color,
                  borderColor: calendaritem.color,
                }"
                class="color-tip-basic"
                @click="changeColorStatus(calendaritem)"
                ref="colortip"
              ></span>
              <span
                v-show="calendaritem.state === '0'"
                class="color-tip-basic color-tip"
                @click="changeColorStatus(calendaritem)"
              ></span>
              <span class="color-tip-title">{{ calendaritem.name }}</span>
            </div>
            <el-dropdown
              trigger="click"
              @visible-change="
                getcalendartype(calendaritem.id, calendaritem.type)
              "
            >
              <div class="selectimg">
                <svg aria-hidden="true" class="icon xiala_ic">
                  <use href="#icon-xiala"></use>
                </svg>
              </div>
              <!-- 仅显示此日历 vue_label_calendar_show_only_this_calendar-->
              <!-- 移除日历 vue_label_calendar_remove_calendar-->
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  @click.native="privatechange(calendaritem.id)"
                  >{{
                    $t("vue_label_calendar_show_only_this_calendar")
                  }}</el-dropdown-item
                >
                <el-dropdown-item @click.native="removedialogVisible = true">{{
                  $t("vue_label_calendar_remove_calendar")
                }}</el-dropdown-item>
                <!-- <el-dropdown-item
                    @click.native="getdeleteid(calendaritem.id,calendaritem.name)"
                >删除日历</el-dropdown-item>-->
                <el-dropdown-item>
                  <div>
                    <chiocecolor
                      @getchiocecolor="getchiocecoloritem"
                    ></chiocecolor>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </li>
      </ul>
      <!-- label_Remove移除 -->
      <!-- 确定移除此日历吗 vue_label_calendar_are_you_sure_to_remove-->
      <el-dialog
        :title="$t('label.Remove')"
        :visible.sync="removedialogVisible"
        width="40%"
        :show-close="false"
      >
        <div class="removecalendar">
          <span>{{ $t("vue_label_calendar_are_you_sure_to_remove") }}</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="removedialogVisible = false">{{
            $t("label.cancel")
          }}</el-button>
          <el-button
            type="primary"
            @click="removecalendarfun"
            :loading="removeloading"
            >{{ $t("label_tabpage_ok") }}</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
/**
 * @description 日历右侧组件
 * 功能：
 * 1. 小日历切换
 * 2. 对象日历列表
 * 3. 添加日历
 * 4. 删除日历
 * 5. 日历颜色
 * 6. 编辑日历
 */
import calendargroup from "./calendargrounp";
import addobjcalendar from "./addobjcalendar";
import sharecalendar from "./sharecalendar";
import editvalendar from "./editcalendar";
import addcalendar from "./addcalendar";
import chiocecolor from "./chiocecolor";
import wangCalendar from "@/components/wangCalendar/main.vue";
import {
  getmycalendarandother,
  deletecalendar,
  changeShowType,
  changecolor,
  onlychange,
  removecalendar,
} from "./api.js";
export default {
  data() {
    return {
      scrollvalue: false,
      myeventmsg: [],
      options: [],
      color1: "rgb(40, 56, 177)",

      // 小日历年值
      yearSelectValue: 0,
      // 小日历月值
      monthsValue: 0,
      // 右侧日历组件年份改变存储其Date instance
      DateInstanceYear: null,
      // 当前要让日历初始化面板的日期
      currentShowDateToCalender: null,
      // 当前选中的日期
      activeDate: "2020/1",
      // 新建公共日历
      dialogVisible: false,
      // 新建对象日历弹窗
      bojdialogVisible: false,
      // 分享日历弹窗
      sharedialogVisible: false,
      // 编辑日历弹窗
      editdialogVisible: false,
      // 删除日历弹窗
      deletedialogVisible: false,
      // 添加日历弹窗
      addcalendardialogVisible: false,
      calendarchild: "",
      // 共享config
      member: { flag: true },
      // 对象日历列表
      mycalendarvalue: [],
      // 我的事件
      myEventItem: {},
      // 其他日历
      myother: [],
      // 当前时间
      nowTime: "",
      // 删除日历ID
      deleteid: "",
      // 删除日历名称
      deletename: "",
      // 编辑日历ID
      deitcalendarid: "",
      // 颜色id
      coloritemid: "",
      // 加载状态
      loading: true,
      // 颜色类型
      coloritemtype: "",
      // 私人日历
      privateid: "",
      // 移除日历弹窗
      removedialogVisible: false,
      // 移除日历loading
      removeloading: false,
    };
  },
  props: {
    // 是否是social日历对象
    simpleDate: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     * @description 移除日历
     */
    removecalendarfun() {
      this.removeloading = true;
      this.removedialogVisible = false;
      removecalendar(this.privateid)
        .then(() => {
          this.removeloading = false;
          this.getmycalendarandothermsg();
          //移除日历成功
          this.$message({
            showClose: true,
            message: this.$i18n.t("vue_label_calendar_removed_successfully"),
            type: "success",
          });
        })
        .catch(() => {
          //移除日历失败
          this.$message({
            showClose: true,
            message: this.$i18n.t(
              "vue_label_calendar_failed_to_remove_calendar"
            ),
            type: "success",
          });
        });
    },
    /**
     * @description 更换对象日历颜色
     */
    getchiocecoloritem(params) {
      changecolor(this.coloritemid, this.coloritemtype, params)
        .then(() => {
          this.getmycalendarandothermsg();
        })
        .catch(() => {});
    },
    //仅显示此对象日历
    /**
     * @description 仅显示此对象日历
     * @param {String} id 日历id
     */
    onlychange(id) {
      onlychange(id, "object", "1")
        .then(() => {
          this.getmycalendarandothermsg();
        })
        .catch(() => {});
    },
    privatechange(id) {
      onlychange(id, "private", "1")
        .then(() => {
          this.getmycalendarandothermsg();
        })
        .catch(() => {});
    },
    getcalendartype(id, type) {
      this.coloritemid = id;
      this.privateid = id;
      this.coloritemtype = type;
    },
    getselectid(id, type) {
      this.coloritemid = id;
      this.coloritemtype = type;
    },
    /**
     * @description 修改菜单展示状态
     */
    async handleChandeState(data) {
      let res = await changeShowType(data);
      if (res.result) {
        this.getmycalendarandothermsg();
      }
    },
    /**
     * @description 修改菜单展示状态
     * @param { item } 选中的日历
     */
    changeColorStatus(item) {
      // if (item.type === 'myevent') {
      //
      //   return
      // }
      let option = {
        id: item.id,
        type: item.type,
        state: item.state === "1" ? "0" : "1",
      };
      this.handleChandeState(option);
    },
    //获取日历列表
    /**
     * @description 获取日历列表
     */
    getmycalendarandothermsg(flag) {
      getmycalendarandother()
        .then((mycalendarandother) => {
          this.loading = false;
          this.mycalendarvalue = mycalendarandother.data.mycalendar.filter(
            (item) => item.type != "myevent"
          );
          this.myeventmsg = mycalendarandother.data.mycalendar.filter(
            (item) => item.type == "myevent"
          );
          mycalendarandother.data.mycalendar.map((item) => {
            if (item.type === "myevent") {
              this.myEventItem = item;
              this.myEventItem.color = item.color || "rgb(88, 22, 139)";
            }
          });
          this.mycalendarvalue.map((item) => {
            if (!item.color) item.color = "#378006";
          });
          this.myother = mycalendarandother.data.other;
          this.myother.map((item) => {
            if (!item.color) item.color = "#378006";
          });
          // 判断是否仅开启其他日历中的一个人的日历
          let calendarArr = mycalendarandother.data.mycalendar.concat(
            mycalendarandother.data.other
          );
          let newArr = calendarArr.filter(
            (item) => item.state == "1" && item.type !== "object"
          );
          let allArr = calendarArr.filter((item) => item.state == "1");
          if (allArr.length === 0) {
            this.$emit("giveTip", 0);
          } else {
            this.$emit("giveTip", 1);
          }
          // type: "myevent"
          // type: "object"
          if (newArr.length === 1 && newArr[0].relationid) {
            // 默认被分配人为仅开启日历的用户
            this.$emit("getOnlyCalendar", newArr[0]);
          } else {
            this.$emit("getOnlyCalendar", {});
          }
          if (flag !== 1) {
            this.$emit("refreshView");
          }
        })
        .catch(() => {});
    },
    /**
     * @description 保存loading
     */
    saveisloading(params) {
      this.$refs.savevalue.loading = params;
      // this.$refs.saveandaddvalue.loading = params;
    },
    /**
     * @description 编辑日历loading
     */
    editisloading(params) {
      this.$refs.editfun.loading = params;
    },
    /**
     * @description 日历分享loadding
     */
    changeshareloading(params) {
      this.$refs.sharefun.loading = params;
    },
    /**
     * @description 添加日历弹窗
     */
    changeaddloaing(params) {
      this.$refs.addfun.loading = params;
    },
    /**
     * @description 编辑日历弹窗
     */
    changeditdisplay(params) {
      this.editdialogVisible = params;
      this.getmycalendarandothermsg();
    },
    /**
     * @description 对象日历弹窗
     */
    changedisplay(params) {
      this.bojdialogVisible = params;
    },
    /**
     * @description 公共日历弹窗
     */
    changepublicdisplay(params) {
      this.dialogVisible = params;
    },
    /**
     * @description 分享日历弹窗
     */
    changesharediplay(params) {
      this.sharedialogVisible = params;
    },
    /**
     * @description 添加日历弹窗
     */
    changeaddcalendarstatus(params) {
      this.addcalendardialogVisible = params;
      this.getmycalendarandothermsg();
    },
    /**
     * @description 选择日期
     */
    selectDate(value) {
      const d = value;
      function p(s) {
        return s < 10 ? "0" + s : s;
      }
      const resDate =
        d.getFullYear() + "-" + p(d.getMonth() + 1) + "-" + p(d.getDate());
      this.$emit("gotoDate", resDate);
    },
    /**
     * @description 打开日历分享弹窗
     */
    sharedialogfun() {
      this.sharedialogVisible = true;
    },
    /**
     * @description 提交公共日历
     */
    submitpublic() {
      this.$refs.calendarchild.commitaddcalendar();
    },
    /**
     * @description 保存新增对象日历
     */
    submitsave() {
      this.$refs.addobjcalendarfun.addnewobject();
    },
    /**
     * @description 提交日历分享
     */
    submitsharesave() {
      this.$refs.sharemsg.saveshareMsg();
    },
    /**
     * @description 添加日历保存
     */
    submitaddcalendarsave() {
      this.$refs.addcalnedar.addCalendarSave();
    },
    /**
     * @description 编辑日历保存
     */
    submiteditobjcalendar() {
      this.$refs.editcalendarname.saveeditobjcalendar();
    },
    /**
     * @description 添加日历
     */
    addcalendarfun() {
      this.addcalendardialogVisible = true;
      this.$nextTick(() => {
        this.$refs.addcalnedar.init();
      });
    },
    /**
     * @description 删除对象日历
     */
    deleteobjcalendar() {
      this.$refs.deletefun.loading = true;
      deletecalendar(this.deleteid)
        .then((res) => {
          if (res.result) {
            this.$refs.deletefun.loading = false;
            this.deletedialogVisible = false;
            this.getmycalendarandothermsg();
            //删除日历成功
            this.$message({
              showClose: true,
              message: this.$i18n.t(
                "vue_label_calendar_calendar_deleted_successfully"
              ),
              type: "success",
            });
          }
        })
        .catch(() => {
          this.$refs.deletefun.loading = false;
          //删除日历失败
          this.$message({
            showClose: true,
            message: this.$i18n.t(
              "vue_label_calendar_failed_to_delete_calendar"
            ),
            type: "error",
          });
        });
    },
    /**
     * @description 获取删除日历的id和name
     */
    getdeleteid(id, name) {
      this.deleteid = id;
      this.deletename = name;
      this.deletedialogVisible = true;
    },
    /**
     * @description 编辑日历
     */
    editcalendar(id) {
      this.deitcalendarid = id;
      this.editdialogVisible = true;
      if (this.$refs.editcalendarname) {
        this.$refs.editcalendarname.geteditobjcalendar(id);
      }
    },
    /**
     * @description 年份改变的函数
     */
    yearChange(e) {
      let date = new Date();
      date.setFullYear(e);
      this.DateInstanceYear = date;
      this.selectDate(date);
      this.currentShowDateToCalender = date;
    },
    /**
     * @description 月份改变的函数
     */
    monthChange(e) {
      let date = new Date();
      date.setFullYear(this.yearSelectValue);
      date.setMonth(e - 1);
      this.selectDate(date);
      this.currentShowDateToCalender = date;
    },
    ebFn(params){
      this.mycalendarvalue.forEach((item) => {
        if (params == item.id) {
          this.$set(item, "isAbnormal", true);
        }
      });
    }
  },
  components: {
    calendargroup,
    addobjcalendar,
    sharecalendar,
    editvalendar,
    addcalendar,
    chiocecolor,
    wangCalendar,
    // wangCalendar: () => import("@/components/wangCalendar/main.vue"),
  },
  mounted() {
    this.getmycalendarandothermsg(1);
    //遍历对象日历列表有无异常日历
    this.$Bus.$on("is-Abnormal-data", this.ebFn);
  },
  created() {
    this.yearSelectValue = this.$dayjs().year();
    this.monthsValue = this.$dayjs().month() + 1;
  },
  beforeDestroy() {
    this.$Bus.$off("is-Abnormal-data", this.ebFn);
  },
  computed: {
    // 年份配置
    yearOptions() {
      let currentYear = this.$dayjs().year() - 10;
      let list = [];
      for (let i = 0; i < 20; i++) {
        list.push({
          label: currentYear++,
        });
      }
      return list;
    },
    // 月份配置
    months() {
      let list = [];
      for (let i = 1; i <= 12; i++) {
        list.push({
          label: i,
        });
      }
      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.calender_style {
  padding: 8px 12px;
  .select_group {
    display: flex;
    justify-content: flex-end;
    .el-select {
      width: 70px;
      height: 24px;
      &:first-child {
        margin-right: 10px;
      }
      ::v-deep .el-input__inner {
        padding: 0;
        padding-left: 5px;
        height: 24px;
        line-height: 24px;
      }
      ::v-deep .el-input__suffix {
        display: flex;
        align-items: center;
      }
    }
  }
}
.xiala_ic {
  width: 20px;
  height: 20px;
}
.warning2 {
  width: 20px;
  height: 20px;
}
.sharecalendar {
  ::v-deep .el-dialog__body {
    border: none;
    overflow: visible;
  }
}
.objectCalendar-icon {
  display: flex;
  align-items: center;
}
.warning2-box {
  margin-right: 5px;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #fff;
  color: #7f8084;
}
::v-deep .el-dropdown-menu__item[data-v-33ff92c6]:not(.is-disabled):hover {
  background-color: #fff;
  color: #7f8084;
}
.taskcalendar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
  margin-left: 3px;
  .obj-item {
    display: flex;
    align-items: center;
  }
  .color-tip-basic {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    display: inline-block;
    margin-right: 15px;
    //background-color: #A094ED;
    border: 1px solid;
  }
}
.color-tip {
  background-color: #ffffff;
  border: 1px solid #d8dde6 !important;
}
.color-tip-title {
  //margin-left: 10px;
}
.calendarcolor {
  display: flex;
  align-items: center;
}
.calendarmin {
  width: 300px;
  height: 100%;
  box-sizing: border-box;
  //overflow-y: auto;
  border-right: none;
  border-top: none;
  background-color: #fff;
  .scrollstatus {
    padding-right: 16px;
  }

  .selectimg {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: var(
      --lwc-buttonColorBackgroundPrimary,
      rgb(255, 255, 255)
    );
    border-color: var(--lwc-buttonColorBorderPrimary, rgb(221, 219, 218));
    img {
      width: 100%;
      height: 100%;
    }
  }
  .calendartype {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px 10px 12px;
    border-top: 1px solid #dcdcdc;
    .mycalendar {
      display: flex;
      align-items: center;
      span {
        margin-left: 0px;
        color: #333333;
        font-weight: bold;
        white-space: nowrap;
        font-size: 15px;
      }
    }
    .addcalendar {
      cursor: pointer;
      display: flex;
      align-items: center;
      i {
        border: 1px solid #dcdcdc;
      }
      .xiala_ic {
        width: 20px;
        height: 20px;
      }
    }
  }
  .eventtype {
    padding: 10px 0 10px 12px;
    //border-top: 1px solid #dcdcdc;
    color: #333333;
    .myevent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //padding-right: 16px;
      margin-left: 3px;
      padding: 5px 16px 5px 0;
      .color-tip-myevent {
        width: 20px;
        height: 20px;
        //border: 1px solid #dcdcdc;
        border-radius: 5px;
        display: inline-block;
        margin-right: 15px;
      }
    }

    .myevent-left {
      display: flex;
      align-items: center;
    }
  }
  .calendars {
    padding: 10px 12px;
    border-top: 1px solid #dcdcdc;
    .subordinate {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span:first-child {
        width: 14px;
        height: 14px;
        border: 1px solid #dcdcdc;
        display: inline-block;
        margin-right: 5px;
        background: #1fb3f3;
      }
    }

    .public {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      span:first-child {
        width: 14px;
        height: 14px;
        border: 1px solid #dcdcdc;
        display: inline-block;
        margin-right: 5px;
        background: #1fb3f3;
      }
    }

    .superior {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      span:first-child {
        width: 14px;
        height: 14px;
        border: 1px solid #dcdcdc;
        display: inline-block;
        margin-right: 5px;
        background: #1fb3f3;
      }
    }
    .closestyle {
      display: flex;
      align-items: center;
      margin-top: 10px;
      span:first-child {
        width: 14px;
        height: 14px;
        border: 1px solid #dcdcdc;
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
  .addcalendar-body {
    .call {
      padding: 10px 20px 0 20px;
      i {
        color: red;
        margin-right: 3px;
      }
    }
    .addcalendar-dai {
      display: flex;
      align-items: center;
      background-color: #f0f0f0;
      padding: 10px 20px;
    }
    .person-name {
      display: flex;
      align-items: center;
      padding: 0 20px 10px 20px;
      .Enable {
        display: flex;
        align-items: center;
        margin-left: 40px;
        span:last-child {
          width: 14px;
          height: 14px;
          border: 1px solid #dcdcdc;
          display: inline-block;
          margin-left: 5px;
        }
      }
    }
    .addshare {
      display: flex;
      align-items: center;
      background-color: #f0f0f0;
      padding: 10px 20px;
    }
    .calen-group {
      padding: 10px 20px;
    }
  }
  .deletebut {
    padding: 30px 20px;
    display: flex;
    justify-content: center;
  }
  ::v-deep .dialog-footer {
    ::v-deep .el-button--primary {
      background-color: #1b5297;
      border-color: #1b5297;
    }
  }
  .type-calendar {
    padding: 10px 20px;
    .chioce-type {
      position: relative;
      i {
        position: absolute;
        right: 9px;
        top: 13px;
      }
    }
  }
  .other {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 16px 15px 16px;
    ::v-deep .el-dialog__body {
      overflow: visible !important;
    }
    .othercalendar {
      display: flex;
      align-items: center;
      span {
        margin-left: 0px;
        color: #333333;
        font-weight: bold;
        //letter-spacing: 2px;
        font-size: 15px;
      }
    }
    .addcalendar {
      display: flex;
      align-items: center;
      cursor: pointer;
      i {
        border: 1px solid #dcdcdc;
      }
      .xiala_ic {
        width: 20px;
        height: 20px;
      }
    }
  }
  .otherlist {
    padding: 0 16px 0 12px;
  }
  .removecalendar {
    border-top: 2px solid #ebeaea;
    padding: 20px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
