<template>
  <div>
    <!-- 第一步：命名对象日历 vue_label_calendar_step_1-->
    <div class="Parttwo">{{ $t("vue_label_calendar_step_1") }}</div>
    <div class="calendar-name">
      <!-- 日历名称 vue_label_calendar_name-->
      <span> <i>*</i>{{ $t("vue_label_calendar_name") }} </span>
      <!-- 请输入名字 vue_label_calendar_please_enter_the_name-->
      <el-input
        v-model="calendarname"
        :placeholder="$t('vue_label_calendar_please_enter_the_name')"
        class="calendarname"
      ></el-input>
    </div>
    <!-- 第二步：选择标准或自定义对象创建日历 vue_label_calendar_step_2-->
    <div class="firststep">{{ $t("vue_label_calendar_step_2") }}</div>
    <div class="object-box">
      <div class="secoend">
        <div class="firststep-name">
          <!-- 对象名称 vue_label_calendar_object_name -->
          <span> <i>*</i>{{ $t("vue_label_calendar_object_name") }} </span>

          <el-select
            v-model="objid"
            filterable
            @change="getid"
            :placeholder="$t('label_tabpage_pleaseSelectz')"
          >
            <el-option
              v-for="(item, index) in allobjlist"
              :key="index"
              :value="item.id"
              :label="item.labelname"
            ></el-option>
          </el-select>
        </div>
        <div class="star-byte">
            <span>
              <!-- 开始字段 vue_label_calendar_start_field-->
              <i>*</i>{{ $t("vue_label_calendar_start_field") }}
            </span>

            <el-select
              v-model="startfile"
              filterable
              :placeholder="$t('label_tabpage_pleaseSelectz')"
            >
              <el-option
                v-for="(item, index) in filterfile"
                :key="index"
                :label="item.labelName + (item.type == 'D' ? `(${$t('label.quickbooks.viewlist.date')})` : `(${$t('dateandtime')})`)"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        <div class="block-byte">
          <span>
            <!-- 显示的字段名称 vue_label_calendar_displayed_field_name-->
            <i>*</i>{{ $t("vue_label_calendar_displayed_field_name") }}
          </span>

          <el-select
            v-model="filemsg"
            filterable
            :placeholder="$t('label_tabpage_pleaseSelectz')"
          >
            <el-option
              v-for="(item, index) in fieldname"
              :key="index"
              :label="item.labelName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>

      <!-- <div class="timeslot">日历项目的开始和结束时间</div> -->
      <!-- label_tabpage_pleaseSelectz请选择 -->
      <div class="view-screen">
        <div class="byte-type">
          <div class="screen">
          <!-- 试图筛选 vue_label_norm_viewfilter-->
          <span>{{ $t("vue_label_norm_viewfilter") }}</span>
          <el-select
            v-model="views"
            filterable
            :placeholder="$t('label_tabpage_pleaseSelectz')"
          >
            <el-option
              v-for="(item, index) in viewlist"
              :key="index"
              :value="item.id"
              :label="item.label"
            ></el-option>
          </el-select>
        </div>
        <div class="screen">
          <!-- 结束字段 front-Dev-V12.2.8-calendar-endField-->
          <span>{{ $t("front-Dev-V12.2.8-calendar-endField") }}</span>
          <el-select
            v-model="endFieldId"
            @visible-change="visibleChange"
            filterable
            clearable
            :disabled="startfile == ''" 
            :placeholder="$t('label_tabpage_pleaseSelectz')"
          >
            <el-option
              v-for="(item, index) in endField"
              :key="index"
              :disabled="item.type != fieldType" 
              :value="item.id"
              :label="item.labelName + (item.type == 'D' ? `(${$t('label.quickbooks.viewlist.date')})` : `(${$t('dateandtime')})`)"
            ></el-option>
          </el-select>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @description: 对象日历新建页面
 */
import {
  getobjectlist,
  getobjfield,
  getviewlist,
  addnewobjectcalendar,
} from "./api.js";
export default {
  props: {
    // 对象日历弹窗
    bojdialogVisible: Boolean,
    // 更新日历列表
    changesonvieew: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      // 对象id
      objid: "",
      // 日历名称
      calendarname: "",
      // 开始字段id
      startfile: "",
      // 显示的字段id
      filemsg: "",
      // 结束字段id
      endFieldId:"",
      // 全部对象列表
      allobjlist: [],
      // 全部字段列表
      fieldname: [],
      // 视图列表
      viewlist: [],
      // 视图id
      views: "",
      // 筛选字段
      filterfile: [],
      // 结束字段列表
      endField: [],
      // 字段类型
      fieldType:""
    };
  },
  methods: {
    /**
     * @description 开始字段和结束字段须为同一类型，否则禁用
     */
    visibleChange(params){
      if(params){
        this.filterfile.forEach((item)=>{
          if(item.id == this.startfile){
            this.fieldType = item.type
          }
        })
      }
    },
    /**
     * @description: 获取对象列表
     */
    async getobjlist() {
      let objlist = await getobjectlist();
      this.allobjlist = objlist.data;
    },
    /**
     * @description: 获取字段
     */
    async getid() {
      this.startfile = "";
      this.views = "";
      this.filemsg = "";
      let fieldnames = await getobjfield(this.objid);
      this.fieldname = fieldnames.data;
      //获取视图
      let objview = await getviewlist(this.objid);
      this.viewlist = objview.data;

      this.filterfile = this.fieldname.filter(
        (item) => item.type == "F" || item.type == "D" || item.type == "T"
      );
      this.endField = this.fieldname.filter(
        (item) => item.type == "F" || item.type == "D"
      );
    },
    /**
     * @description 提交新建对象日历
     */
    
    addnewobject() {
      if (this.calendarname == "") {
        //请填写日历名称
        this.$message({
          showClose: true,
          message: this.$i18n.t(
            "vue_label_calendar_please_fill_in_the_calendar"
          ),
          type: "error",
        });
      } else if (this.objid == "") {
        // 请选择对象
        this.$message({
          showClose: true,
          message: this.$i18n.t("vue_label_notice_pleaseselectobject"),
          type: "error",
        });
      } else if (this.startfile == "") {
        //请选择开始字段
        this.$message({
          showClose: true,
          message: this.$i18n.t("vue_label_calendar_please_select_the_start"),
          type: "error",
        });
      } else if (this.filemsg == "") {
        //请选择显示的字段
        this.$message({
          showClose: true,
          message: this.$i18n.t("vue_label_calendar_please_select"),
          type: "error",
        });
      } else {
        this.$emit("changeisloading", true);
        let obj = {};
        obj.name = this.calendarname;
        obj.objectid = this.objid;
        obj.startfield = this.startfile;
        obj.endfield = this.endFieldId;
        obj.viewid = this.views;
        obj.type = 'object'
        obj.showfield = this.filemsg;
        addnewobjectcalendar(obj)
          .then((res) => {
            if (res.result) {
              this.$emit("changeisloading", false);
              this.$emit("changestatus", false);
              //新建对象日历成功
              this.$message({
                showClose: true,
                message: this.$i18n.t("vue_label_calendar_notice_havecreated"),
                type: "success",
              });
              this.changesonvieew();
              this.calendarname = "";
              this.objid = "";
              this.startfile = "";
              this.views = "";
              this.filemsg = "";
            }
          })
          .catch(() => {
            this.$emit("changeisloading", false);
            //新建对象日历失败
            this.$message({
              showClose: true,
              message: this.$i18n.t("vue_label_calendar_notice_createfail"),
              type: "error",
            });
            this.calendarname = "";
            this.objid = "";
            this.startfile = "";
            this.views = "";
            this.filemsg = "";
          });
      }
    },
  },
  mounted() {
    this.getobjlist();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner{
   height: 30px;
   line-height: 30px;
}
::v-deep .el-select__caret,.el-input__icon,.el-icon-arrow-up{
  line-height: 30px;
}
.object-box {
  display: flex;
}
.screen {
  padding: 10px 20px;
  span {
    display: block;
    i {
      color: red;
      margin-right: 2px;
    }
  }
}
.block-byte {
  padding: 10px 20px 0 20px;
  span {
    display: block;
    i {
      color: red;
      margin-right: 2px;
    }
  }
}
.star-byte {
    padding: 10px 20px;
    span {
      display: block;
      i {
        color: red;
        margin-right: 2px;
      }
    }
  }
.secoend {
}
.firststep {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px 20px;
}
.firststep-name {
  padding: 10px 20px;
  span {
    display: block;
    i {
      color: red;
      margin-right: 2px;
    }
  }
}
.Parttwo {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px 20px;
}

.calendar-name {
  padding: 10px 20px;
  span {
    display: block;
    i {
      color: red;
      margin-right: 2px;
    }
  }
  .calendarname {
    width: 221px;
  }
}

.timeslot {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px 20px;
}

.byte-type {
  .end-byte {
    padding: 20px 20px;
    span {
      display: block;
      i {
        color: red;
        margin-right: 2px;
      }
    }
  }
}

.view-screen {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
</style>
