<template>
  <div>
    <div>
      <ul>
        <li
          @click="chioceitem(item.color)"
          v-for="item in coloroptions"
          :key="item.id"
          :style="{ backgroundColor: item.color, borderColor: item.color }"
        ></li>
      </ul>
    </div>
    <!-- 选择此日历的自定义颜色 -->
    <div class="chiocetext">
      {{ $t("vue_label_calendar_choose_a_custom_color") }}
    </div>
  </div>
</template>


<script>
/**
 * @description: 选择颜色组件
 */
export default {
  data() {
    return {
      // 固定的颜色配置
      coloroptions: [
        {
          id: 1,
          color: "#E0B2E7",
        },
        {
          id: 2,
          color: "#C0C7E6",
        },
        {
          id: 3,
          color: "#A5D4F9",
        },
        {
          id: 4,
          color: "#94DED2",
        },
        {
          id: 5,
          color: "#95E7B7",
        },
        {
          id: 6,
          color: "#F9E992",
        },
        {
          id: 7,
          color: "#F9CD94",
        },
        {
          id: 8,
          color: "#BA38BB",
        },
        {
          id: 9,
          color: "#5778C0",
        },
        {
          id: 10,
          color: "#418DDD",
        },
        {
          id: 11,
          color: "#05ACA7",
        },
        {
          id: 12,
          color: "#41B84E",
        },
        {
          id: 13,
          color: "#F6BA2A",
        },
        {
          id: 14,
          color: "#F79222",
        },
        {
          id: 15,
          color: "#58168B",
        },
        {
          id: 16,
          color: "#011F70",
        },
        {
          id: 17,
          color: "#132798",
        },
        {
          id: 18,
          color: "#137375",
        },
        {
          id: 19,
          color: "#126B53",
        },
        {
          id: 20,
          color: "#B47C18",
        },
        {
          id: 21,
          color: "#B65D16",
        },
      ],
    };
  },
  methods: {
    /**
     * @description: 选择颜色
     * @param {String} color 颜色
     */
    chioceitem(color) {
      this.$emit("getchiocecolor", color);
    },
  },
};
</script>


<style lang="scss" scoped>
::v-deep .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover{
  color: black;
}

ul {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  align-content: space-between;
  width: 120px;
}
li {
  height: 12px;
  width: 12px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
}
</style>