<!-- 表头选择标题 -->
<template>
  <div>
    <div class="row">
      <div class="groupseach">
        <!-- label_quickbooks_search搜索 -->
        {{ $t("label.quickbooks.search") }}
      </div>
      <div class="dragminbox">
        <div class="dragBox">
        <div class="select-type">
          <!-- label_tabpage_pleaseSelectz请选择 -->
            <el-select v-model="curRole" @change="handleCurRole" :placeholder='$t("label_tabpage_pleaseSelectz")'>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="{value:item.value,label:item.label}"
              ></el-option>
            </el-select>
        </div>
        <div>
          <!-- label_usableshareuser可用共享者 -->
          <span>{{ $t("label.usableshareuser") }}</span>
        <div class="shuttle">
          <div class="shuttle_sea" v-if="optionArr.flag">
            
          </div>
          <draggable
            class="list-group ms-column itemBox shu_left"
            v-model="originFields"
            group="tasks"
            animation="300"
          >
            <span
              class="dragItem"
              v-for="(element,index) in originFields"
              :key="index"
            >{{element.text}}</span>
          </draggable>
        </div>
        </div>
      </div>
      <div class="dragBox" id="background">
       <div>
         <div class="searchDiv">
           <!-- label_placeholder请输入搜索关键字 -->
              <el-input :placeholder='$t("label.placeholder")' class="left_inp" v-model="searchVal"></el-input>
            </div>
            <!-- label_shareusers共享用户 -->
          <span>{{ $t("label.shareusers") }}</span>
        <draggable
          class="list-group ms-column itemBox shu_right"
          v-model="curGroupList"
          group="tasks"
          @start="onStart"
          sort="true"
        >
          <!-- <b class="pad_s" v-if="optionArr.flag">{{this.curRole}}</b> -->
          <p
            class="dragItem pad_s"
            v-for="(element,index) in curGroupList"
            :key="index"
          >
            <template v-if="optionArr.flag">
              <b>{{element.title}}</b>
              <span>{{element.text}}</span>
            </template>
          </p>
        </draggable>
       </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {SearchGroup,addnewcalendar} from './api.js';

export default {
  userArr: [],
  name: "app",
  components: {
    draggable,
  },
  props: ["optionArr", "selectedGroupList","calendarname","dialogstatus"],
  data() {
    return {
      searchVal: "",
      options: [
        {
          value: "User",
          //用户
          label:this.$i18n.t("label.document.history.user"),
        },
        {
          value: "Role",
          //角色
          label: this.$i18n.t("label_tabpage_rolez"),
        },
        {
          value: "RoleAndSub",
          //角色及下属
          label:  this.$i18n.t("label.roleandunderling"),
        },
        {
          value: "area",
          //区域
          label:this.$i18n.t('label.area'),
        },
        {
          value: "areaAndSub",
          //区域及下级区域
          label:this.$i18n.t('label.areaAndSub'),
        },
        {
          value: "Group",
          //公用小组
          label: this.$i18n.t("label.publicgroup"),
        },
        {
          value: "partnerUser",
          //合作伙伴用户
          label: this.$i18n.t("label.partneruser"),
        },
        {
          value: "partnerRole",
          //合作伙伴角色
          label:this.$i18n.t("label.roleofpartner"),
        },
      ],
      curRole: this.$i18n.t("label.document.history.user"),
      curRoleId: "User",
      originFields: [],
      curGroupList: [],
      newArr:[]
    };
  },
 mounted() {
    this.searchGroup();
  },
  methods: {
    //删除右侧内容
    deleGroup(val) {
      this.curGroupList = this.curGroupList.filter(
        (obj) => obj.text !== val.text
      );
      this.curRoleId = this.originFields[0].type;
      this.options.forEach((item, ) => {
        if (item.value === val.type) {
          this.curRole = item.label;
          this.curRoleId = val.type;
          this.searchGroup();
        }
      });
    },
    // 搜索
    // search() {
    //   this.searchGroup();
    // },
    // 搜索用户组
    // searchGroup() {
    //   const params = {
    //     searchKeyWord: this.searchVal,
    //     type: this.curRoleId,
    //   };
    //   SearchGroup(params).then((res) => {
    //     this.originFields = res.data;
    //   });
    // },
    searchGroup() {
      const params = {
        searchKeyWord: this.curRole,
        type: this.curRoleId,
      };
      SearchGroup(params).then((res) => {
        this.originFields = res.data;
        this.newArr = res.data
        // this.newArr = res.data.filter(item=>{
        //   this.curGroupList.forEach(val=>{
        //     return item => item.text !== val.text
        //   })
        // })
      });
    },
    //搜索用户组
    handleCurRole(params) {
      const { value, label } = params;
      this.curRole = label;
      this.curRoleId = value;
      this.searchGroup();
    },
    //提交新建公共日历
   async commitaddcalendar(){
      this.curGroupList.forEach((item,)=>{
        let obj = {}
        obj.sharemember = item.id.substring(item.id.indexOf(":")+1,item.id.length)
        obj.sharetype = item.type
        obj.type = 'public'
        obj.permission = 'detail'
        this.sharelist.push(obj)
      })
      let resultStatus =await addnewcalendar(this.calendarname,this.sharelist)
      if (resultStatus.result == true && resultStatus.data !=null) {
          this.$emit('changepublic',false)
          this.$message({showClose: true,
          message: '新建公共日历成功',
          type: 'success'
        });
        this.curGroupList = []
        this.sharelist = []
      }else{
        this.$message({showClose: true,
          message: '新建公共日历失败',
          type: 'error'
        });
        this.curGroupList = []
        this.sharelist = []
      }
      
    },
    onStart() {},
  },
  watch: {
    searchVal(nval, ) {
      this.originFields = this.newArr.filter((item) => {
        return item.text.indexOf(nval) !== -1
      })
    },
    newArr(){
    },
    curGroupList(nval, ) {
      this.curGroupList = nval;
      let selecteArr = [];
      this.curGroupList.forEach((val, ) => {
        selecteArr.push(val.id);
      });
      this.$emit("memberFun", selecteArr.join(","));
    },
    selectedGroupList(nval, ) {
      this.curGroupList = nval;
      let selecteArr = [];
      this.curGroupList.forEach((val, ) => {
        selecteArr.push(val.id);
      });
      this.$emit("memberFun", selecteArr.join(","));
    },
  },
};
</script>

<style lang="scss" scoped>
.dragminbox{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.select-type{
  margin-bottom: 20px;
}
.groupseach{
  padding-bottom: 5px;
}
.searchDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 220px;
    margin-bottom: 20px;
    ::v-deep .el-input__inner{
          padding-right: 20px;
    }
    i{
      position: absolute;
      right: 6px;
      top:8px;
    }
  }
.shuttle_sea ::v-deep {
  display: flex;
  position: relative;
  .el-select {
    
  }
}

.dragBox {
  
}

.shuttle {
  width: 240px;
  height: 212px;
  border: 1px solid #ddd;
  position: relative;
}
.shuttle ::v-deep input {
  width: 90%;
  border: 0;
  background: none;
  outline: none;
  border: none;
  font-size: 12px;
}

.shu_right {
  height: 212px;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
  .pad_s {
    padding-left: 10px;
    margin: 0;
  }
  span {
    padding-left: 20px;
  }
}

.shu_left {
  height: 170px;
  // border-top: 1px solid #ddd;
  cursor: pointer;
}
.shu_left span,
.shu_right span {
  font-size: 12px;
}

.itemBox {
  width: 240px;
  padding: 5px 0;
  overflow: auto;
}

.dragItem {
  width: 100%;
  height: 30px;
  display: inline-block;
  line-height: 30px;
  padding: 0 10px;

  &:hover {
    background: #ddd;
  }
}

.btns {
  width: 100%;
  margin: 10px 15px 10px 0;
  text-align: right;
}
</style>