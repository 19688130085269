<template>
  <div v-loading="loading1">
    <!-- 第一步：命名对象日历 vue_label_calendar_step_1-->
    <div class="Parttwo">{{ $t("vue_label_calendar_step_1") }}</div>
    <!-- 日历名称 vue_label_calendar_name-->
    <div class="calendar-name">
      <span> <i>*</i>{{ $t("vue_label_calendar_name") }} </span>
      <!-- 请输入名字 vue_label_calendar_please_enter_the_name-->
      <el-input
        v-model="calendarname"
        :placeholder="$t('vue_label_calendar_please_enter_the_name')"
        class="calendarname"
      ></el-input>
    </div>
    <!-- 第二步：选择标准或自定义对象创建日历 vue_label_calendar_step_2-->
    <div class="firststep">{{ $t("vue_label_calendar_step_2") }}</div>
    <div class="object-box">
      <div class="secoend">
        <div class="firststep-name">
          <!-- 对象名称 vue_label_calendar_object_name -->
          <span> <i>*</i>{{ $t("vue_label_calendar_object_name") }} </span>

          <el-select v-model="objid" filterable @change="changeObject">
            <el-option
              v-for="(item, index) in allobjlist"
              :key="index"
              :value="item.id"
              :label="item.labelname"
            ></el-option>
          </el-select>
        </div>
        <div class="byte-type">
          <div class="star-byte">
            <span>
              <!-- 开始字段 vue_label_calendar_start_field-->
              <i>*</i>{{ $t("vue_label_calendar_start_field")}}
            </span>

            <el-select v-model="startfile" filterable>
              <el-option
                v-for="(item, index) in filterfile"
                :key="index"
                :label="item.labelName + (item.type == 'D' ? `(${$t('label.quickbooks.viewlist.date')})` : `(${$t('dateandtime')})`)"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="block-byte">
          <span>
            <!-- 显示的字段名称 vue_label_calendar_displayed_field_name-->
            <i>*</i>{{ $t("vue_label_calendar_displayed_field_name") }}
          </span>

          <el-select
            v-model="filemsg"
            filterable
            :placeholder="$t('label_tabpage_pleaseSelectz')"
          >
            <el-option
              v-for="(item, index) in fieldname"
              :key="index"
              :label="item.labelName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>

      <!-- <div class="timeslot">日历项目的开始和结束时间</div> -->
      <!-- label_tabpage_pleaseSelectz请选择 -->
      <div class="view-screen">
        <div class="screen">
          <!-- 试图筛选 vue_label_norm_viewfilter-->
          <span>{{ $t("vue_label_norm_viewfilter") }}</span>
          <el-select
            v-model="views"
            filterable
            :placeholder="$t('label_tabpage_pleaseSelectz')"
          >
            <el-option
              v-for="(item, index) in viewlist"
              :key="index"
              :value="item.id"
              :label="item.label"
            ></el-option>
          </el-select>
        </div>
        <div class="screen">
          <span>结束字段</span>
          <el-select
            v-model="endFieldId"
            @visible-change="visibleChange"
            filterable
            :disabled="startfile == ''" 
            :placeholder="$t('label_tabpage_pleaseSelectz')"
          >
            <el-option
              v-for="(item, index) in filterEnd"
              :disabled="item.type != fieldType" 
              :key="index"
              :value="item.id"
              :label="item.labelName + (item.type == 'D' ? `(${$t('label.quickbooks.viewlist.date')})` : `(${$t('dateandtime')})`)"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @description: 对象日历编辑页面
 */
import {
  getobjectlist,
  getobjfield,
  getviewlist,
  editobjcalendar,
  saveeditcalendar,
} from "./api.js";
export default {
  props: {
    // 编辑对象日历弹窗
    bojdialogVisible: Boolean,
    // 对象日历id
    deitcalendarid: String,
    // 更新日历列表
    changesonvieew: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      filterstartid: "",
      filterstart: [],
      filterEnd:[],
      filterviewid: "",
      filterview: [],
      filtershowid: "",
      filtershow: [],
      filterobjid: "",
      filterobj: [],
      // 结束字段id
      endFieldId:"",
      // 对象id
      objid: "",
      // 对象日历名称
      calendarname: "",
      // 开始字段id
      startfile: "",
      // 显示的字段id
      filemsg: "",
      // 对象列表      
      allobjlist: [],
      // 字段列表
      fieldname: [],
      // 视图列表
      viewlist: [],
      // 筛选字段
      filterfile: [],
      // 视图id
      views: "",
      // 记录id
      recordid: "",
      // 加载
      loading1: true,
      // 字段类型
      fieldType:""
    };
  },
  methods: {
    /**
     * @description: 开始字段和结束字段须为同一类型，否则禁用
     */
    visibleChange(params){
      if(params){
        this.filterfile.forEach((item)=>{
          if(item.id == this.startfile){
            this.fieldType = item.type
          }
        })
      }
    },
    /**
     * @description: 切换对象
     */
   async changeObject(){
      this.startfile = "";
      this.endFieldId = "";
      this.views = "";
      this.filemsg = "";
      let fieldnames = await getobjfield(this.objid);
      this.fieldname = fieldnames.data;
      //获取视图
      let objview = await getviewlist(this.objid);
      this.viewlist = objview.data;

      this.filterfile = this.fieldname.filter(
        (item) => item.type == "F" || item.type == "D" || item.type == "T"
      );
      this.filterEnd = fieldnames.data.filter(
        (item) => item.type == "F" || item.type == "D"
      );
    },
    /**
     * @description: 获取编辑日历数据
     */
    geteditobjcalendar(id) {
      this.loading1 = true;
      this.calendarname = "";
      this.objid = "";
      this.startfile = "";
      this.endFieldId = "";
      this.views = "";
      this.filemsg = "";
      editobjcalendar(id)
        .then((res) => {
          this.calendarname = res.data.calendar.name;
          this.filterstartid = res.data.calendar.startfield;
          this.endFieldId = res.data.calendar.endfield;
          this.filterviewid = res.data.calendar.viewid;
          this.filtershowid = res.data.calendar.showfield;
          this.filterobjid = res.data.calendar.objectid;
          this.recordid = res.data.calendar.id;
          this.getobjlist();
        })
        .catch(() => {});
    },
    /**
     * @description: 获取对象列表
     */
    getobjlist() {
      getobjectlist()
        .then((objlist) => {
          this.allobjlist = objlist.data;
          this.filterobj = this.allobjlist.filter(
            (item) => item.id == this.filterobjid
          );
          this.objid = this.filterobj[0].id;
          this.getid();
        })
        .catch(() => {});
    },
    /**
     * @description: 获取字段列表
     */
    getid() {
      getobjfield(this.objid)
        .then((fieldnames) => {
          this.fieldname = fieldnames.data;
          //开始字段
          this.filterfile = this.fieldname.filter(
            (item) => item.type == "F" || item.type == "D" || item.type == "T"
          );
          //结束字段
          this.filterEnd = fieldnames.data.filter(
            (item) => item.type == "F" || item.type == "D"
          );
          this.filterstart = this.filterfile.filter(
            (item) => item.id == this.filterstartid
          );
          this.filtershow = this.fieldname.filter(
            (item) => item.id == this.filtershowid
          );
          this.startfile = this.filterstart[0].id;
          this.filemsg = this.filtershow[0].id;
          this.loading1 = false;
        })
        .catch(() => {});

      //获取视图
      getviewlist(this.objid)
        .then((objview) => {
          if (objview.data == null) {
            this.views = "";
          } else {
            this.viewlist = objview.data;
            this.filterview = this.viewlist.filter(
              (item) => item.id == this.filterviewid
            );
            this.views = this.filterview[0].id;
          }
        })
        .catch(() => {});
    },
    /**
     * @description: 提交编辑对象日历
     */
    saveeditobjcalendar() {
      if (this.calendarname == "") {
        //请填写日历名称
        this.$message({
          showClose: true,
          message: this.$i18n.t(
            "vue_label_calendar_please_fill_in_the_calendar"
          ),
          type: "error",
        });
      } else if (this.objid == "") {
        // 请选择对象
        this.$message({
          showClose: true,
          message: this.$i18n.t("vue_label_notice_pleaseselectobject"),
          type: "error",
        });
      } else if (this.startfile == "") {
        //请选择开始字段
        this.$message({
          showClose: true,
          message: this.$i18n.t("vue_label_calendar_please_select_the_start"),
          type: "error",
        });
      } else if (this.filemsg == "") {
        //请选择显示的字段
        this.$message({
          showClose: true,
          message: this.$i18n.t("vue_label_calendar_please_select"),
          type: "error",
        });
      } else {
        this.$emit("changeisloading", true);
        saveeditcalendar(
          this.recordid,
          this.calendarname,
          this.objid,
          this.startfile,
          this.endFieldId,
          this.views,
          this.filemsg,
          "object"
        )
          .then((res) => {
            if (res.result) {
              this.$emit("changeisloading", false);
              this.$emit("changeeditstatus", false);
              //编辑对象日历成功
              this.$message({
                showClose: true,
                message: this.$i18n.t("savesuccess"),
                type: "success",
              });
              this.changesonvieew();
            }
          })
          .catch(() => {
            this.$emit("changeisloading", false);
          });
      }
    },
  },
  mounted() {
    this.geteditobjcalendar(this.deitcalendarid);
  },
};
</script>

<style lang="scss" scoped>
.object-box{
  display: flex;
}
.secoend {
}
.firststep {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px 20px;
}
.firststep-name {
  padding: 10px 20px 0 20px;
  span {
    display: block;
    i {
      color: red;
      margin-right: 2px;
    }
  }
}
.Parttwo {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px 20px;
}

.calendar-name {
  padding: 10px 20px;
  span {
    display: block;
    i {
      color: red;
      margin-right: 2px;
    }
  }
  .calendarname {
    width: 221px;
  }
}

.timeslot {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px 20px;
}

.byte-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .star-byte {
    padding: 10px 20px;
    span {
      display: block;
      i {
        color: red;
        margin-right: 2px;
      }
    }
  }
  .end-byte {
    padding: 10px 20px;
    span {
      display: block;
      i {
        color: red;
        margin-right: 2px;
      }
    }
  }
}
.block-byte {
    padding: 0 20px;
    span {
      display: block;
      i {
        color: red;
        margin-right: 2px;
      }
    }
  }

.view-screen {
  margin-bottom: 20px;
  .screen {
    padding: 10px 20px 0 20px;
    span {
      display: block;
      i {
        color: red;
        margin-right: 2px;
      }
    }
  }
}
</style>
