<template>
  <div class="addcalendarbox">
    <!-- <div class="personselect">
      <el-select v-model="value1" placeholder>
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
          <div style="display: flex; align-items: center">
            <svg class="icon-svg" style="margin-right: 10px" aria-hidden="true">
              <use :href="item.src"></use>
            </svg>
            <span class="personname">{{ item.label }}</span>
          </div>
        </el-option>
      </el-select>
      <div class="postionimg">
        <svg class="icon-svg" aria-hidden="true">
          <use :href="value1 == 'User' ? options[0].src : options[1].src"></use>
        </svg>
      </div>
    </div> -->

    <div>
      <el-select
            v-model="multiselectvalue"
             filterable
             remote
             :placeholder='$t("label.file.search.people")'
             @visible-change="cleararr"
             :reserve-keyword="false"
             :remote-method="asyncFind"
             :loading="isLoading">
             <el-option
             v-for="item in countries"
             :key="item.id"
             :label="item.name"
             :value="item.id">
             </el-option>
           </el-select>
    </div>
  </div>
</template>


<script>
/**
 * @description 添加人员的日历
 * 功能： 搜索并添加人员的日历
 */
import { getpersonalORmsg, addcalendarsave } from "./api.js";
export default {
  data() {
    return {
      value: [],
      // 搜索人员或者队列
      options: [
        {
          value: "User",
          //人员
          label: this.$i18n.t("label.weixin.group.member"),
          src: "#icon-guanzhuzhe",
        },
      ],
      // 选中的人员
      multiselectvalue: "",
      // 人员列表
      countries: [],
      // 接口请求loading
      isLoading: false,
      // 搜索人员类型
      value1: "User",
    };
  },
  methods: {
    /**
     * 初始化
     */
    init() {
      this.multiselectvalue = ""
    },
    /**
     * @description 选中选项后清空列表
     */
    cleararr(params) {
      if(params){
        this.countries = [];
        this.asyncFind();
      } 
    },
    /**
     * @description 异步搜索人员列表
     */
    async asyncFind(query) {
      if(query !=""){
         this.isLoading = true;
         let personORmsg = await getpersonalORmsg(query, this.value1);
      if (personORmsg.data == null) {
        this.countries = [];
        this.isLoading = false;
      } else {
        this.countries = personORmsg.data;
        this.isLoading = false;
      }
      }  
    },
    /**
     * @description 清空所有选中的人员
     */
    
    clearAll() {
      this.selectedCountries = [];
    },
    /**
     * @description 添加人员日历
     */
    
    addCalendarSave() {
      if (this.multiselectvalue == "") {
        // 不执行操作
      } else {
        this.$emit("addloading", true);
        addcalendarsave(this.multiselectvalue, "private")
          .then((res) => {
            this.$emit("addloading", false);
            if (res.result == true && res.data != null) {
              this.$emit("changeaddcalendarsave", false);
              this.$message({
                showClose: true,
                //添加日历成功
                message: this.$i18n.t(
                  "vue_label_calendar_calendar_added_successfully"
                ),
                type: "success",
              });
              this.multiselectvalue = "";
              this.countries = [];
            }
          })
          .catch(() => {
            this.$emit("addloading", false);
          });
      }
    },
  },
  watch: {
    value1() {
      this.asyncFind();
      this.multiselectvalue = "";
    },
  },
  mounted() {
    this.asyncFind();
  },
};
</script>

<style lang="scss" scoped>
.icon-svg {
  width: 21px;
  height: 21px;
}
::v-deep .multiselect__option--highlight {
  background-color: rgb(243, 242, 242);
  color: black;
}
::v-deep .multiselect__tag {
  background-color: #1b5297;
}
::v-deep .multiselect__input {
  font-size: 14px;
}
</style>
