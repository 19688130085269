import axios from '@/config/httpConfig'


// 获取表单信息
export function getFromDetail(data) {
    return axios.post('/objectdetail/getDetail', data)
}
// 修改展示状态
export function getRecType(data) {
    return axios.post('/salesFunnel/getRecordTypes', data)
}
// 修改展示状态
export function changeShowType(data) {
    return axios.post('/calendar/changeShowType', data)
}
// 获取记录的编辑权限
export function getEditPermissions(data) {
    return axios.post('/object/getEditPermissions', data)
}
// 获取所有的日历的条目
export function getAllItems(args) {
    return axios.post('/calendar/getAllItems', args)
}
//获取侧边栏日历列表
export function getCalendarList() {
    return axios.post('/calendar/getCalendarList', {}, {

    })
}
//获取共享信息
export function getsharemsg() {
    return axios.get('/calendar/getShareInfo', {

    })
}
//获取对象列表
export function getobjectlist() {
    return axios.post('/object/getAllObjectList', {
        searchKeyWord: ''
    }, {

    })
}
//获取对象字段列表
export function getobjfield(objId) {
    return axios.post('/field/getFieldList', {
        objId: objId
    }, {

    })
}
//获取视图列表
export function getviewlist(objId) {
    return axios.post('/view/list/getViewList', {
        objId: objId
    }, {

    })
}

export function SearchGroup(data) {
    return axios.post('/view/searchGroup', data, {

    })
}
//新建公共日历
export function addnewcalendar(name, listShare) {
    return axios.post('/calendar/save', {
        name: name,
        type: 'public',
        inuse: '1',
        listShare
    }, {

    })
}
//新建对象日历
export function addnewobjectcalendar(params) {
    return axios.post('/calendar/save', params, {

    })
}

//获取用户列表
export function getuserlist(keywords, num, selectids) {
    return axios.post('/calendar/getUsersByRole', {
        keywords: keywords,
        num: num,
        selectids: selectids
    }, {

    })
}
//保存共享信息
export function savesharemsg(list) {
    return axios.post('/calendar/saveShareInfo', list, {

    })
}
//获取已共享的信息
export function getsharevalue() {
    return axios.get('/calendar/getSharedUsers', {

    })
}

//添加日历-获取人员或日历信息
export function getpersonalORmsg(name, type) {
    return axios.post('/calendar/getSharedCalendar', {
        name: name,
        type: type
    }, {

    })
}
//添加日历-保存
export function addcalendarsave(relationid, type) {
    return axios.post('/calendar/observe', {
        relationid: relationid,
        type: type
    }, {

    })
}


//查询我的日历和其他日历
export function getmycalendarandother() {
    return axios.post('/calendar/getCalendarList', {

    })
}
//删除日历
export function deletecalendar(id) {
    return axios.post('/calendar/delete', {
        id: id,
    }, {

    })
}
//移除已共享单条信息
export function deletepeople(id) {
    return axios.post('/calendar/deleteShareInfo', {
        id: id,
    }, {

    })
}

//编辑对象日历
export function editobjcalendar(id) {
    return axios.post('/calendar/edit', {
        id: id,
    }, {

    })
}

//保存编辑对象日历
export function saveeditcalendar(id, name, objectid, startfield, endfield, viewid, showfield, type) {
    return axios.post('/calendar/save', {
        id: id,
        name: name,
        objectid: objectid,
        startfield: startfield,
        endfield: endfield,
        viewid: viewid,
        showfield: showfield,
        type: type
    }, {

    })
}

//修改日历颜色
export function changecolor(id, type, color) {
    return axios.post('/calendar/changeColor', {
        id: id,
        type: type,
        color: color
    }, {

    })
}

//仅展示此日历
export function onlychange(id, type, state) {
    return axios.post('/calendar/changeShowOnly', {
        id: id,
        type: type,
        state: state,
    }, {

    })
}

//移除日历
export function removecalendar(id) {
    return axios.post('/calendar/removeCalendar', {
        id: id,
    }, {

    })
}

// 添加或更新对象记录
export function save(data) {
    return axios.post('/objectInfo/save', data)
}
