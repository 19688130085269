<template>
  <div>
    <div class="sharetype">
      <!-- 与他人共享日历 vue_label_calendar_share_calendar_with_others-->
      <div class="sharetypeother">
        <span>{{ $t("vue_label_calendar_share_calendar_with_others") }}</span>

        <div class="my-multiselect">
          <el-select
            v-model="value"
            multiple
            filterable
            remote
            :placeholder="$t('label.file.search.people')"
            @visible-change="cleararr"
            :reserve-keyword="false"
            :remote-method="asyncFind"
            :loading="isLoading"
          >
            <el-option
              v-for="item in countries"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <!-- 访问权限 -->
        <div>{{ $t("label.foldervisitlimit") }}</div>
        <div>
          <span
            style="
              width: 185px;
              height: 42px;
              display: block;
              border: 1px solid #e8e8e8;
              border-radius: 3px;
              line-height: 42px;
              text-align: center;
            "
          >
            <!-- label_showdetail显示详细信息 -->
            {{ $t("label.showdetail") }}</span
          >
        </div>
      </div>
    </div>
    <div class="jurisdiction">
      <el-collapse>
        <!-- 目前谁拥有访问权限 vue_label_calendar_who_currently_has_access-->
        <el-collapse-item
          :title="$t('vue_label_calendar_who_currently_has_access')"
        >
          <div class="sharepeoplesbox">
            <div class="owner">
              <span>{{ ownername }}</span>
              <!-- 所有人 -->
              <span>{{ $t("label.referperson") }}</span>
            </div>
            <div class="sharepeoplemsg" v-if="sharepeoplemsgs">
              <ul>
                <li
                  v-for="(item, index) in sharepeoplelist"
                  :key="item.sharemember"
                >
                  <div>{{ item.sharemembername }}</div>
                  <div>
                    <!-- 显示详细信息并添加事件 -->
                    <span>{{
                      $t("vue_label_calendar_show_details_and_add_events")
                    }}</span>
                    <i
                      class="el-icon-close"
                      @click="
                        getdeleteid(
                          item.id,
                          item.sharemembername,
                          index,
                          item.sharemember
                        )
                      "
                    ></i>
                  </div>
                </li>
              </ul>
              <!-- button_partner_delete删除 -->
              <el-dialog
                :title="$t('button_partner_delete')"
                :visible.sync="deletedialogVisible"
                width="30%"
                :append-to-body="true"
              >
                <!-- 是否停止共享日历 -->
                <span>{{ $t("vue_label_calendar_stopsharing") }}</span>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="deletedialogVisible = false">{{
                    $t("label.cancel")
                  }}</el-button>
                  <el-button type="primary" @click="deletefun">{{
                    $t("label_tabpage_ok")
                  }}</el-button>
                </span>
              </el-dialog>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>


<script>
/**
 * @description: 共享日历组件
 */
import {
  // getsharemsg,
  getuserlist,
  getsharevalue,
  savesharemsg,
  deletepeople,
} from "./api.js";
export default {
  data() {
    return {
      input: "",
      selectedCountries: [],
      countries: [],
      state: "",
      restaurants: [],
      resultarr: [],
      value: [],

      // 加载loading
      isLoading: false,
      // 删除弹窗
      deletedialogVisible: false,
      sharepeoplemsgs: false,
      // 分享的人员列表
      sharepeoplelist: [],
      // id集合
      idarr: [],
      // 删除的id
      deleteaid: "",
      // 删除的name
      deletename: "",
      // 删除的成员
      deletesharemember: "",
      // 删除的index
      deleteindex: null,
      // 用户名
      ownername: localStorage.getItem("username"),
    };
  },
  mounted() {
    this.sharepeoplemsg();
  },
  methods: {
    /**
     * @description: 清空列表
     */
    cleararr(params) {
      if (params) {
        this.countries = [];
        this.asyncFind();
      }
    },
    /**
     * @description: 获取人员信息
     */
    sharepeoplemsg() {
      getsharevalue()
        .then((res) => {
          if (res.data != null) {
            this.idarr = [];
            this.sharepeoplemsgs = true;
            this.sharepeoplelist = res.data;
            this.sharepeoplelist.forEach((item) => {
              let obj = {};
              obj.permission = "";
              obj.type = "";
              obj.relationid = "";
              obj.sharetype = "";
              obj.sharemember = item.sharemember;
              this.resultarr.unshift(obj);
              this.idarr.push(item.sharemember);
            });
          } else {
            this.sharepeoplemsgs = false;
          }
        })
        .catch(() => {});
    },
    /**
     * @description: 获取人员列表
     */
    async asyncFind(query) {
      if (query != "") {
        let idstr = this.idarr.join(",");
        this.isLoading = true;
        let personlist = await getuserlist(query, "", idstr);
        if (personlist.data == null) {
          this.countries = [];
          this.isLoading = false;
        } else {
          this.countries = personlist.data;
          this.isLoading = false;
        }
      }
    },
    /**
     * 清除全部
     */
    clearAll() {
      this.selectedCountries = [];
    },
    /**
     * @description: 保存共享人员
     */
    async saveshareMsg() {
      if (this.value.length == 0) {
        this.$emit("changshare", false);
      } else {
        this.value.forEach((item, ) => {
          let obj = {};
          obj.permission = "";
          obj.type = "";
          obj.relationid = "";
          obj.sharetype = "";
          obj.sharemember = item;
          this.resultarr.push(obj);
        });
        this.$emit("shareloading", true);
        savesharemsg(this.resultarr)
          .then(() => {
            this.$emit("shareloading", false);
            this.sharepeoplemsg();
            this.$emit("changshare", false);
            this.resultarr = [];
            this.value = [];
          })
          .catch(() => {
            this.$emit("shareloading", false);
            //共享日历失败
            this.$message({
              showClose: true,
              message: this.$i18n.t(
                "vue_label_calendar_failed_to_share_calendar"
              ),
              type: "error",
            });
            this.resultarr = [];
            this.value = [];
          });
      }
    },
    /**
     * @description: 获取删除人员信息
     */
    getdeleteid(id, name, index, sharemember) {
      this.deletedialogVisible = true;
      this.deleteaid = id;
      this.deletename = name;
      this.deleteindex = index;
      this.deletesharemember = sharemember;
    },
    /**
     * @description: 删除人员
     */
    deletefun() {
      deletepeople(this.deleteaid)
        .then(() => {
          this.deletedialogVisible = false;
          this.sharepeoplelist.splice(this.deleteindex, 1);
          this.idarr.splice(this.deleteindex, 1);
          this.resultarr = this.resultarr.filter((item) => {
            return item.sharemember != this.deletesharemember;
          });
        })
        .catch(() => {
          //停止共享失败
          this.$message({
            showClose: true,
            message: this.$i18n.t("vue_label_calendar_notice_stopsharingfail"),
            type: "error",
          });
        });
    },
  },
};
</script>


<style lang="scss" scoped>
::v-deep .el-input--suffix .el-input__inner {
  height: 42.8px;
}
::v-deep .el-button--primary {
  background-color: #1b5297;
  border-color: #1b5297;
}
.sharepeoplesbox {
  max-height: 242px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
::v-deep .multiselect__option--highlight {
  background-color: rgb(243, 242, 242);
  color: black;
}
::v-deep .multiselect__tag {
  background-color: #1b5297;
}
::v-deep .multiselect__tags {
  padding-right: 35px;
  width: 100%;
}
::v-deep .multiselect__input {
  font-size: 14px;
}
.sharetype {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
  ::v-deep .multiselect {
    width: 185px;
  }
  .sharetypeother {
    margin-right: 30px;
    position: relative;
    ::v-deep .el-input__inner {
      padding: 0 33px;
    }
    .personimg {
      position: absolute;
      top: 29px;
      left: 6px;
    }
    img:last-child {
      position: absolute;
      top: 32px;
      right: 8px;
      cursor: pointer;
    }
  }
}
.jurisdiction {
  padding: 20px 20px 0 20px;
  ::v-deep .el-collapse-item__header {
    border: none;
  }
  ::v-deep .el-collapse {
    border: none;
  }
  ::v-deep .el-collapse-item__wrap {
    border: none;
  }
  ::v-deep .el-collapse-item__arrow {
    margin: 0;
  }
  .owner {
    border: 1px solid #dcdcdc;
    span:nth-child(1) {
      width: 80%;
      height: 40px;
      line-height: 40px;
      display: inline-block;
      padding-left: 20px;
      color: #666666;
    }
    span:nth-child(2) {
      width: 20%;
      height: 40px;
      line-height: 40px;
      display: inline-block;
      border-left: 1px solid #dcdcdc;
      text-align: center;
    }
  }
  .sharepeoplemsg {
    li {
      border: 1px solid #dcdcdc;
      height: 40px;
      line-height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: none;
      padding: 0 20px;
      i {
        margin-left: 10px;
        font-size: 15px;
        cursor: pointer;
      }
    }
  }
}
</style>